<template>
    <h1> Terms of Use </h1>
    <h4> Legal Information & Notices </h4>
    <div>
    <h3> Ownership of Site; Agreement to Terms of Use </h3>
    <p> These Terms and Conditions of Use (the "Terms of Use") apply to the 
        Elkhorn Valley Family Medicine web site located at www.elkhornvalleyfamilymedicine.com, 
        and all associated sites linked to www.elkhornvalleyfamilymedicine.com by Elkhorn Valley 
        Family Medicine, and affiliates, (collectively, the "Site"). The Site is the property 
        of Elkhorn Valley Family Medicine ("EVFM") BY USING THE SITE, YOU AGREE TO 
        THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE.
    </p>
    <p> EVFM reserves the right, at its sole discretion, to change, modify, add or remove 
        portions of these Terms of Use, at any time. It is your responsibility to check these 
        Terms of Use periodically for changes. Your continued use of the Site following the 
        posting of changes will mean that you accept and agree to the changes. As long as you 
        comply with these Terms of Use, EVFM grants you a personal, non-exclusive, non-transferable, 
        limited privilege to enter and use the Site.
    </p>

    <h3> Content </h3>
    <p> All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, 
         and computer code (collectively, "Content"), including but not limited to the design, 
         structure, selection, coordination, expression, "look and feel" and arrangement of such 
         Content, contained on the Site is owned, controlled or licensed by or to EVFM, and is 
         protected by trade dress, copyright, patent and trademark laws, and various other 
         intellectual property rights and unfair competition laws.
    </p>
    <p> Except as expressly provided in these Terms of Use, no part of the Site and no Content 
        may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, 
        translated, transmitted or distributed in any way (including "mirroring") to any other 
        computer, server, Web site or other medium for publication or distribution or for any 
        commercial enterprise, without EVFM's express prior written consent. 
    </p>
    
    <h3> Your Use of the Site </h3>
    <p>You may not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, 
        program, algorithm or methodology, or any similar or equivalent manual process, to access, 
        acquire, copy or monitor any portion of the Site or any Content, or in any way reproduce 
        or circumvent the navigational structure or presentation of the Site or any Content, 
        to obtain or attempt to obtain any materials, documents or information through any means 
        not purposely made available through the Site. EVFM reserves the right to bar any such 
        activity.
    </p>
    <p> You may not attempt to gain unauthorized access to any portion or feature of the Site, 
        or any other systems or networks connected to the Site or to any EVFM server, or to any 
        of the services offered on or through the Site, by hacking or any other illegitimate means.
    </p>
    <p> You may not probe, scan or test the vulnerability of the Site or any network connected 
        to the Site, nor breach the security or authentication measures on the Site or any 
        network connected to the Site. You may not reverse look-up, trace or seek to trace 
        any information on any other user of or visitor to the Site to its source, or exploit 
        the Site or any service or information made available or offered by or through the Site, 
        in any way where the purpose is to reveal any information, including but not limited to 
        personal identification or information, other than your own information, as provided 
        for by the Site.
    </p>
    <p> You agree that you will not take any action that imposes an unreasonable or 
        disproportionately large load on the infrastructure of the Site, or any systems or 
        networks connected to the Site or to EVFM.
    </p>
    <p> You agree not to use any device, software or routine to interfere or attempt to interfere 
        with the proper working of the Site or any transaction being conducted on the Site, or 
        with any other person’s use of the Site.
    </p>
    <p> You may not forge headers or otherwise manipulate identifiers in order to disguise the 
        origin of any message or transmittal you send to EVFM on or through the Site or any 
        service offered on or through the Site. You may not pretend that you are, or that 
        you represent, someone else, or impersonate any other individual or entity.
    </p>
    <p> You may not use the Site or any Content for any purpose that is unlawful or prohibited 
        by these Terms of Use, or to solicit the performance of any illegal activity or other 
        activity which infringes the rights of EVFM or others.
    </p>

    <h3> Other Terms and Conditions </h3>
    <p> EVFM's obligations, if any, with regard to its services are governed solely by the 
        agreements pursuant to which they are provided, and nothing on this Site should be 
        construed to alter such agreements.
    </p>
    <p> EVFM may make changes to any of it services described on the Site, or to the 
        applicable prices for any such products or services, which may or may not be listed, 
        at any time, without notice. The materials on the Site with respect to services may 
        be out of date, and EVFM makes no commitment to update the materials on the Site with 
        respect to services.
    </p>

    <h3> Accounts, Passwords and Security </h3>
    <p> Certain features or services offered on or through the Site may require you to open 
        an account. You are entirely responsible for maintaining the confidentiality of the 
        information you hold for your account, including your password, and for any and all 
        activity that occurs under your account as a result of your failing to keep this 
        information secure and confidential. EVFM cannot and will not be liable for any loss 
        or damage arising from your failure to comply with these obligations.
    </p>

    <h3> Privacy </h3>
    <p> EVFM's Privacy Policy applies to use of this Site. To view EVFM's Privacy Policy, 
        <a href="/privacy-policy">click here</a>. Additionally, by using the Site, you 
        acknowledge and agree that Internet transmissions are never completely private 
        or secure. You understand that any message or information you send to the Site 
        may be read or intercepted by others, even if there is a special notice that 
        a particular transmission is encrypted.
    </p>

    <h3> Links to Other Sites and to the EVFM Site </h3>
    <p> This Site may contain links to other independent third-party Web sites ("Linked Sites"). 
        These Linked Sites are provided solely as a convenience to our visitors. Such Linked 
        Sites are not under EVFM's control, and EVFM is not responsible for and does not endorse 
        the content of such Linked Sites, including any information or materials contained on 
        such Linked Sites. You will need to make your own independent judgment regarding your 
        interaction with these Linked Sites.
    </p>

    <h3> Disclaimer </h3>
    <p> EVFM DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL 
        BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE 
        OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN 
        "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS SUBJECT TO 
        CHANGE WITHOUT NOTICE. EVFM CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM 
        THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. EVFM DISCLAIMS 
        ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, 
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EVFM DISCLAIMS ANY AND ALL LIABILITY 
        FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO 
        YOUR USE OF THE SITE AND/OR ANY EVFM SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR 
        USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST EVFM FOR DISSATISFACTION 
        WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS 
        LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
    </p>
    <p> The above disclaimer applies to any damages, liability or injuries caused by any failure 
        of performance, error, omission, interruption, deletion, defect, delay in operation or 
        transmission, computer virus, communication line failure, theft or destruction of or 
        unauthorized access to, alteration of, or use, whether for breach of contract, tort, 
        negligence or any other cause of action.
    </p>
    <p> EVFM reserves the right to do any of the following, at any time, without notice: 
        (1) to modify, suspend or terminate operation of or access to the Site, or any portion 
        of the Site, for any reason; (2) to modify or change the Site, or any portion of the 
        Site, and any applicable policies or terms; and (3) to interrupt the operation of 
        the Site, or any portion of the Site, as necessary to perform routine or non-routine 
        maintenance, error correction, or other changes.
    </p>

    <h3> Indemnity </h3>
    <p> You agree to indemnify and hold EVFM, its owners, employees, agents, and affiliates, 
        harmless from any demands, loss, liability, claims or expenses (including attorneys’ 
        fees), made against EVFM by any third party due to or arising out of or in 
        connection with your use of the Site.
    </p>

    <h3> Violation of These Terms of Use </h3>
    <p> EVFM may disclose any information we have about you if we determine that such 
        disclosure is necessary in connection with any investigation or complaint regarding 
        your use of the Site, or to identify, contact or bring legal action against someone 
        who may be causing injury to or interference with (either intentionally or 
        unintentionally) EVFM's rights or property, or the rights or property of visitors 
        to or users of the Site. EVFM reserves the right at all times to disclose any 
        information that EVFM deems necessary to comply with any applicable law, regulation, 
        legal process or governmental request. EVFM also may disclose your information when 
        EVFM determines that applicable law requires or permits such disclosure, including 
        exchanging information with other companies and organizations for fraud protection 
        purposes.
    </p>
    <p> You acknowledge and agree that EVFM may preserve any transmittal or communication by 
        you with EVFM through the Site or any service offered on or through the Site, and may 
        also disclose such data if required to do so by law or EVFM determines that such 
        preservation or disclosure is reasonably necessary to (1) comply with legal process, 
        (2) enforce these Terms of Use, (3) respond to claims that any such data violates 
        the rights of others, or (4) protect the rights, property or personal safety of EVFM, 
        its employees, users of or visitors to the Site, and the public.
    </p>
    <p> You agree that EVFM may, in its sole discretion and without prior notice, terminate 
        your access to the Site and/or block your future access to the Site if we determine 
        that you have violated these Terms of Use or other agreements or guidelines which 
        may be associated with your use of the Site.
    </p>
    <p> You agree that EVFM may, in its sole discretion and without prior notice, terminate 
        your access to the Site, for cause, which includes (but is not limited to) (1) 
        requests by law enforcement or other government agencies, (2) a request by you, 
        (3) discontinuance or material modification of the Site or any service offered on 
        or through the Site, or (4) unexpected technical issues or problems.
    </p>
    <p> You agree that EVFM will not be liable to you or to any third party for termination 
        of your access to the Site as a result of any violation of these Terms of Use.
    </p>

    <h3> Governing Law; Dispute Resolution </h3>
    <p> You agree that all matters relating to your access to or use of the Site, including 
        all disputes, will be governed by the laws of the United States and by the laws of the 
        State of Nebraska without regard to its conflicts of laws provisions.
    </p>

    <h3> Void Where Prohibited </h3>
    <p> EVFM administers and operates the <a href="/">
        www.elkhornvalleyfamilymedicine.com</a> Site from a third-party location in the 
        United States. Although the Site is accessible worldwide, services discussed, 
        referenced, provided or offered through or on the Site are not available to all 
        persons or in all geographic locations, or appropriate or available for use 
        outside the United States. EVFM reserves the right to limit, in its sole discretion, 
        the provision and quantity of any feature, product or service to any person or 
        geographic area. Any offer for any service made on the Site is void where prohibited. 
        If you choose to access the Site from outside the United States, you do so on your 
        own initiative and you are solely responsible for complying with applicable local laws.
    </p>

    <h3> Miscellaneous </h3>
    <p> You may not use or export or re-export any Content or any copy or adaptation of such 
        Content, or any product or service offered on the Site, in violation of any applicable 
        laws or regulations, including without limitation United States export laws and regulations.
    </p>

    <h3> Feedback and Information </h3>
    <p> Any feedback you provide at this site shall be deemed to be non-confidential. EVFM shall be 
        free to use such information on an unrestricted basis.
    </p>
    <p> <i> The information contained in this web site is subject to change without notice. </i>
    <br />
    <i> Elkhorn Valley Family Medicine, 304 East Douglas Street, O'Neill, NE 68763, USA </i>
    </p>
    </div>

</template>

<script>
export default {
    name: "Terms of Use"
}
</script>

<style scoped>
    div {
        width: 70%;
        margin: auto;
        text-align: left;
    }
</style>